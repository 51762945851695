
.DialogCampaignBodyEditor-content {
    z-index: 900;
    height: 100% !important;
    width: 100% !important;
}

.DialogCampaignBodyEditor-lock {
    z-index: 200;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: repeating-linear-gradient( 
        135deg, rgba(150, 150, 150, 0.5), rgba(150, 150, 150, 0.5) 1px, rgba(150, 150, 150, 0.5) 1px, rgba(0, 0, 0, 0.5) 4px );
}

.DialogCampaignBodyEditor-lock-buttons {
    position: fixed;
    bottom: 20px !important;
    right: 30px !important;
    z-index: 260 !important;
    background-color: #EEE !important;
    border: 2px solid rgb(187, 187, 187);
    padding: 2px 10px 8px 10px;
}

.DialogCampaignBodyEditor-lock-buttons button {
    display: block !important;
    opacity: 1;
    width: 100%;
    text-align: left;
}

.DialogCampaignBodyEditor-customised {
    z-index: 250;
    position: relative;
    background-color: #EEE;
}

.DialogCampaignBodyEditor-preview-box {
    display: block;
    border: solid 5px lightgrey;
    background-color: white;
    width: 100%;
}

.DialogCampaignBodyEditor-preview-box * {
    all: revert;
}

.DialogCampaignBodyEditor-customised .Info ul {
    margin-bottom: 0px;
}