
.FormAnswer-title {
    width: calc(100% - 250px);
    min-height: 40px;
}

.FormAnswer-content {
    white-space: initial !important;
}

.FormAnswer-question {
    background-color: lightgrey;
    padding: 10px;
    margin: 10px 0px;
}

.FormAnswer-answer {
    padding: 10px;
    margin: 10px 0px;
}