
.DialogSelectDocument-overlay > .DialogSelectDocument-content {
	width: 80% !important;
	height: 100%;
}

.DialogSelectDocument-buttons {
	width: 100%;
	text-align: right;
	margin: 15px 0px;
}

.DialogSelectDocument-buttons > div {
	margin: 0px 2px;
	display: inline;
}

.DialogSelectDocument-select-button {
	text-align: center;
}