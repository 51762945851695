
@media (min-width: 0px) {
    .Tab-menu {
        width: 100%;
    }

    .Tab-menu, .Tab-menu-fw {
        padding: 20px 0px;
        margin-bottom: 50px;
    }
}

@media (min-width: 768px) {
    .Tab-menu {
        position: absolute;
        width: calc(100% - 10px);
    }

    .Tab-menu, .Tab-menu-fw {
        padding: 20px 0px;
        margin-left: 0px;
    }

    .Tab-menu-el-fw {
        float: left;
    }
}

.Tab-menu-el, .Tab-menu-el-fw {
    cursor: pointer;
    color: #010101;
    text-transform: uppercase;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 10px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.Tab-menu-el {
    width: inherit;
    max-width: inherit;
}

.Tab-menu-el:hover, .Tab-menu-el-fw:hover {
    background-color: var(--light-primary-color);
}

.Tab-menu-el-selected {
    border-left: 4px solid var(--primary-color);
    border-bottom: none !important;
    background-color: var(--light-primary-color);
}

.Tab-menu-el-selected-fw {
    border-bottom: 4px solid var(--primary-color);
    border-left: none !important;
    background-color: var(--light-primary-color);
}

.Tab-menu-el-selected:hover {
    background-color: var(--hovered-primary-color);
}

.Tab-menu-el-empty {
    height: 15px;
}